import {v4 as uuid} from 'uuid';

const ServicesToDisplay = [
    {
        id: uuid(),
        name:'Full Stack Engineering',
        subtitle: 'From frontend to backend applications',
        related: [
            {
                dId: uuid(),
                detail: "Building a web application using the React Framework"
            },
            {
                dId: uuid(),
                detail: "Designing a modern and responsive website using Figma"
            },
            {
                dId: uuid(),
                detail: "Developing and maintaining a back-end application using ASP.NET Framework"
            },
            {
                dId: uuid(),
                detail: "Designing relatioal/noSQL databases and querying with SQL, graphQL"
            },
            {
                dId: uuid(),
                detail: "Developing and deploying the back-end application using AWS"
            }
        ]
    },
    {
        id: uuid(),
        name:'Cloud DevOps Engineering',
        subtitle: 'From integrating AWS into a development to workflow management,',
        related: [
            {
                dId: uuid(),
                detail: "Designing, updating, and maintaining cloud resources to perfectly fit the purpose of the project"
            },
            {
                dId: uuid(),
                detail: "Incorporating Cloud resources and framework into previously-made projects"
            },
            {
                dId: uuid(),
                detail: "Installing an automated CI/CD pipeline to simplify the productivity"
            },
            {
                dId: uuid(),
                detail: "Deploying and optimizing the application using a load balancer and auto-scaling"
            }
        ]
    }
];

export {ServicesToDisplay};
