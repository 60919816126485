import React from 'react';

const Button = ({children, url, onClick=null})=>{
    return (
        <a className="link-button" href={url} onClick={onClick}>
            {children}
        </a>
    );
};

export {Button};
