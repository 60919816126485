import {v4 as uuid} from 'uuid';

/*
    This file contains my portfolio list.
*/

const WorksToDisplay = [
    // {
    //     id: uuid(),
    //     name:'JazzNote Demo',
    //     url:'https://github.com/Pavelbure34/Jazznote-demo',
    //     image: "https://alistairesuhimages.s3.amazonaws.com/portfolios/JazzNote.png",
    //     category: ['Personal Project', 'Frontend'],
    //     details: 'My personal front-end project. I built it as a blog template.'
    // },
    // {
    //     id: uuid(),
    //     name:'Google Map Project',
    //     url:'https://github.com/Pavelbure34/googlemapsproject',
    //     image: "https://alistairesuhimages.s3.amazonaws.com/portfolios/henry-perks-BJXAxQ1L7dI-unsplash.jpg",
    //     category1: 'web',
    //     categort2: 'Web'
    // },
    // {
    //     id:3,
    //     name:'Eat!T',
    //     description:'Mobile Application for food ordering. Made with Ionic React.',
    //     url:'https://github.com/Pavelbure34/Eatit',
    //     image:'https://alistairesuhimages.s3.amazonaws.com/Eat!T.png',
    //     type:'personal project'
    // },
    {
        id: uuid(),
        name:'Algorithmic Trading',
        url:'https://github.com/Pavelbure34/AlgorithmicTrading',
        image: "https://alistairesuhimages.s3.amazonaws.com/portfolios/carlos-muza-hpjSkU2UYSU-unsplash.jpg",
        category: ['Machine Learning', 'Data Science', 'Personal Project'],
        details: 'My personal Data Science/ML project. I fetch stock data and predict the price.'
    },
    {
        id: uuid(),
        name:'Algorithmic Betting',
        url:'https://github.com/Pavelbure34/AlgorithmicBetting',
        image: "https://alistairesuhimages.s3.amazonaws.com/portfolios/luke-chesser-JKUTrJ4vK00-unsplash.jpg",
        category: ['Machine Learning', 'Data Science', 'Personal Project'],
        details: 'My personal Data Science/ML project. I fetch baseball playoff and horse race data and predict the winner.'
    },
    {
        id: uuid(),
        name:'Single Page Template',
        url:'https://github.com/Pavelbure34/SinglePagePortfolioTemplate',
        image: "https://alistairesuhimages.s3.amazonaws.com/portfolios/halgatewood-com-tZc3vjPCk-Q-unsplash.jpg",
        category: ['Personal Project', 'Frontend'],
        details: 'My personal front-end project. I built it as a blog template.'
    },
    // {
    //     id: uuid(),
    //     name:'My First GraphQL',
    //     url:'https://github.com/Pavelbure34/MyFirstGraphQL',
    //     image: "https://alistairesuhimages.s3.amazonaws.com/portfolios/nasa-Ed2AELHKYBw-unsplash.jpg",
    //     category: ['Personal Project','Backend'],
    //     details: 'My personal back-end project. I made it to practice my graphQL skills.'
    // },
    // {
    //     id:6,
    //     name:'My First Django-Docker',
    //     url:'https://github.com/Pavelbure34/myFirstDjangoDocker',
    //     image:default_img,
    //     category1: '',
    //     categort2: ''
    // },
    {
        id: uuid(),
        name:'Graph',
        url:'https://github.com/Pavelbure34/graph',
        image: "https://alistairesuhimages.s3.amazonaws.com/portfolios/israel-palacio-ImcUkZ72oUs-unsplash.jpg",
        category: ['Data Structure', 'School Project', 'C++'],
        details: 'C++ Implementation of a data structure Graph.'
    },
    // {
    //     id: uuid(),
    //     name:'Red-Black Tree',
    //     url:'https://github.com/Pavelbure34/rbtree',
    //     image: "https://alistairesuhimages.s3.amazonaws.com/portfolios/todd-quackenbush-XBxQZLNBM0Q-unsplash.jpg",
    //     category1: 'Data Structure',
    //     categort2: 'Data Structure'
    // },
    {
        id: uuid(),
        name:'Hash Table',
        url:'https://github.com/Pavelbure34/hashtable',
        image: "https://alistairesuhimages.s3.amazonaws.com/portfolios/jan-baborak-ZKOwF_J-3rw-unsplash.jpg",
        category: ['Data Structure', 'School Project', 'C++'],
        details: 'C++ Implementation of a data structure Hash Table'
    },
    {
        id: uuid(),
        name:'Minimum Heap',
        url:'https://github.com/Pavelbure34/minimum_heap',
        image: "https://alistairesuhimages.s3.amazonaws.com/portfolios/markus-spiske-IiEFmIXZWSw-unsplash.jpg",
        category: ['Data Structure', 'School Project', 'C++'],
        details: 'C++ Implementation of a data structure Minimum Heap'
    }
];

const WorksByCategory = {
    'All': WorksToDisplay,
    'Personal Project': WorksToDisplay.filter(work=> work.category.includes('Personal Project')),
    'School Project': WorksToDisplay.filter(work=> work.category.includes('School Project')),
    'Data Structure': WorksToDisplay.filter(work=> work.category.includes('Data Structure')),
    'Frontend': WorksToDisplay.filter(work=> work.category.includes('Frontend')),
    // 'Backend': WorksToDisplay.filter(work=> work.category.includes('Backend')),
    'Machine Learning': WorksToDisplay.filter(work=> work.category.includes('Machine Learning')),
    'C++': WorksToDisplay.filter(work=> work.category.includes('C++')),
    // 'Data Science': WorksToDisplay.filter(work=> work.category.includes('Data Science'))
}

export {WorksToDisplay, WorksByCategory};
