import React, {
    createContext,
    useState,
    useRef
} from 'react';

const AppContext = createContext({
    lang: '',
    setLang: ()=>{},
    postID: '',
    setPostID: ()=>{},
    tagID: '',
    setTagID: ()=>{},
    categoryID: '',
    setCategoryID: ()=>{}
});

const AppContextProvider = ({children})=>{
    const [lang, setLang] = useState('en');
    let postID = useRef('');
    let tagID = useRef('');
    let categoryID = useRef('');

    const setPostID = (id)=>postID.current = id;
    const setTagID = (id)=>tagID.current = id;
    const setCategoryID = (id)=>categoryID.current = id;

    const {Provider} = AppContext;
    return (
        <Provider value={{
            lang,
            setLang,
            postID: postID.current,
            setPostID,
            tagID: tagID.current,
            setTagID,
            categoryID: categoryID.current,
            setCategoryID
        }}>
            {children}
        </Provider>
    );
};

export {AppContextProvider, AppContext};
