import {v4 as uuid} from 'uuid';

const PostsToDisplay = [
    {
        id: uuid(),
        category1: 'web',
        category2: 'React',
        title:'The 4 Most Useful Packages for your React Projects',
        url: '/blog/4mostusefulpackagesforyourreactprojects',
        alt_url:'https://medium.com/@suh_d1/the-4-most-useful-packages-for-react-projects-320b012fab82',
        image:'https://alistairesuhimages.s3.amazonaws.com/programme.jpg',
    },
    {
        id: uuid(),
        category1: 'design',
        category2: 'Beginner',
        title:'3 Best Programming Languages for Beginners',
        url:'/blog/3bestprogramminglanguagesforbeginners',
        alt_url:'https://medium.com/@suh_d1/3-best-programming-languages-for-beginners-2b2b557d5e94',
        image:'https://alistairesuhimages.s3.amazonaws.com/posts/emile-perron-xrVDYZRGdw4-unsplash.jpg',
    },
    {
        id: uuid(),
        category1: 'branding',
        category2: 'Algorithm',
        title:'What is Big-O',
        url:'/blog/whatisbigo',
        alt_url:'https://medium.com/@suh_d1/what-is-big-o-80d1c156c61',
        image:'https://alistairesuhimages.s3.amazonaws.com/posts/jeff-fielitz-60WxQTZ8iq8-unsplash.jpg',
    }
];

export {PostsToDisplay};
