import {v4 as uuid} from 'uuid';

const Testimonials  = [
    {
        id: uuid(),
        image: '',
        name: 'Jaimie Kelley',
        quote: 'Alistaire is a bright, enterprising go-getter with a thirst to prove himself. He did so well in Computer Systems that I asked him to serve as Teaching Assistant for the following semester. Alistaire actively seeks out new projects to improve his skills and learns quickly. He would be an asset to any software engineering team or graduate program.'
    },
    {
        id: uuid(),
        image: '',
        name: 'Jonathan Stevens',
        quote: 'Alistaire was a great asset to our team. When he first joined he had little experience with php applications and by the end was doing both front and backend work on a large Drupal project. Alistaire has a real passion for learning and will burn the candle at both ends to get things done and as well as extend his knowledge. '
    },
    {
        id: uuid(),
        image: '',
        name: 'Sylvester Amponsah',
        quote: 'Alistaire is one of those rare talents that joined KyFlex at a time when the need for his skills could not be overstated. In an early-stage startup, one of the best talents to attract is someone with an incredibly high learning rate and Alistaire is exactly that.'
    },
    {
        id: uuid(),
        image: '',
        name: 'Joy Lodra',
        quote: 'Alistaire is a leader who communicates well and guides people with passion. I am currently working together as a team in KyFlex. When I first entered the startup, I did not have any knowledge about React.js, but he guided me through until I was finally able to code the actual website of KyFlex. '
    }
];

export {Testimonials};
