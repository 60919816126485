import React, {useState} from 'react';
import emailjs from 'emailjs-com';
import {Form, Button} from 'react-bootstrap';
import {ContentContainer} from '../components'
const MY_EMAIL = "dongbinsuh@gmail.com";

/*
    This page is contact.
    User can send email to me and see my contact information.
*/

const Contact = ()=>{
    const [from, setFrom] = useState('');
    const [message, setMessage] = useState('');

    const SendMail = ()=>{
        if (from.trim().length === 0)
            alert("Please use a valid email");
        else if (message.trim().length === 0)
            alert("Please write a message.");
        else
            emailjs.send(
                process.env.REACT_APP_EMAIL_JS_SERVICE_CODE,
                process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID,
                {
                    'to_name': MY_EMAIL,
                    'from_name':from,
                    'message': message,
                },
                process.env.REACT_APP_EMAIL_JS_USER_ID
            ).then(()=>{
                alert('Thanks, your mail is sent!');
                setFrom("");
                setMessage("");
            }).catch(err=>alert('Oops... ' + JSON.stringify(err)));
    };

    const {Group, Label, Control} = Form;
    return (
        <ContentContainer id="contact">
            <div className="content">
                <div>
                    <h2>Contact</h2>
                    <p>
                        Feel free to contact me!
                    </p>
                </div>

                <Form onSubmit={e=>{
                    e.preventDefault();
                    SendMail();
                }}>
                    <Group className="mb-3" controlId="contact-form-from">
                        <Label>From</Label>
                        <Control
                            type="email"
                            placeholder="your@email.com"
                            value={from}
                            onChange={e=>setFrom(e.target.value)}
                        />
                    </Group>
                    <Group className="mb-3" controlId="contact-form-to">
                        <Label>To {MY_EMAIL}</Label>
                    </Group>
                    <br/>
                    <Group className="mb-3" controlId="contact-form-message">
                        <Label>Message</Label>
                        <Control
                            as="textarea"
                            rows={10}
                            from={message}
                            onChange={e=>setMessage(e.target.value)}
                        />
                    </Group>
                    <Button variant="dark" size="lg" type="submit">
                        Send
                    </Button>
                </Form>
            </div>
        </ContentContainer>
    );
};

export {Contact};
