import {v4 as uuid} from 'uuid';

const SkillsToDisplay = [
    {
        id: uuid(),
        skill: 'React'
    },
    {
        id: uuid(),
        skill: 'JavaScript'
    },
    {
        id: uuid(),
        skill: 'C#'
    },
    {
        id: uuid(),
        skill: 'Python'
    },
    {
        id: uuid(),
        skill: 'C++'
    },
    {
        id: uuid(),
        skill: 'HTML/CSS'
    },
    {
        id: uuid(),
        skill: 'AWS'
    },
    {
        id: uuid(),
        skill: 'SQL'
    },
    {
        id: uuid(),
        skill: 'GraphQL'
    },
    {
        id: uuid(),
        skill: 'Git'
    }
];

export {SkillsToDisplay};
