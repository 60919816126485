import React from 'react';
import {Button} from '../components';

const Home = ()=>{
    const resume = "https://alistairesuhimages.s3.amazonaws.com/Docs/Dongbin_resume+_typeA.pdf";
    // const certificate = "https://alistairesuhimages.s3.amazonaws.com/Docs/AWS+Certified+Solutions+Architect+-+Associate+certificate.pdf";
    
    return (
        <div id="home">
            <div className="content">
                <p>
                    Hi, I am Dongbin Suh. I go by Alistaire.<br/>
                    I'm a software engineer experienced with Go, Python, and JavaScript.<br/>
                    My interests are fintech, VR/AR, and investment.
                </p>
                <p>
                    <Button url={resume}>
                        Resume
                    </Button>
                    {/* {' '}
                    <Button url={certificate}>
                        Certificate
                    </Button> */}
                </p>
            </div>
        </div>
    );
};

export {Home};
