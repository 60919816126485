import React from 'react';
import {FocusOnTop} from './hooks';
import {
  Home,
  Works,
  Contact
} from './pages';
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

const App = ()=>{
  FocusOnTop();

  return (
    <div>
        <header>
            <h1 className="logo"><a href="/">Alistaire Suh</a></h1>
            <input type="checkbox" id="nav-toggle" className="nav-toggle"/>
            <nav>
                <ul>
                    <li><a href="/about">About Me</a></li>
                    {/* <li><a href="/works">My Works</a></li> */}
                    <li><a href="/contact">Contact</a></li>
                </ul>
            </nav>
            <label for="nav-toggle" className="nav-toggle-label">
                <span>{' '}</span>
            </label>
        </header>

        <Switch>
          <Route exact path="/">
            <Home/>
          </Route>
          <Route exact path="/about">
            <Home/>
          </Route>
          {/* <Route exact path="/works">
            <Works/>
          </Route> */}
          <Route exact path="/contact">
            <Contact/>
          </Route>
          <Redirect to="/"/>
        </Switch>
    </div>
  );
};

export default App;
